import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'promo-configurator',
    loadChildren: () =>
      import('./sync-promo-configurator/sync-promo-configurator.module').then(
        (m) => m.SyncPromoConfiguratorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then((m) => m.ProgramsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'blast-campaign',
    loadChildren: () => import('./blast-campaigns/blast-campaigns.module').then((m) => m.BlastCampaignsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quick-email',
    loadChildren: () => import('./quick-email/quick-email.module').then((m) => m.QuickEmailModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'email-template',
    loadChildren: () => import('./email-template/email-template.module').then((m) => m.EmailTemplateModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'content-admin',
    loadChildren: () => import('./content-admin/content-admin.module').then((m) => m.ContentAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'web-guides',
    loadChildren: () => import('./web-guides/web-guides.module').then((m) => m.WebGuidesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'client-guides',
    loadChildren: () => import('./client-guides/client-guides.module').then((m) => m.ClientGuidesModule),
    canActivate: [AuthGuard],
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
