import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  showSuccessToast(msg) {
    this.toastr.success(msg);
  }

  showErrorToast(msg) {
    console.log('called');
    this.toastr.error(msg);
  }

  showInfoToast(msg) {
    this.toastr.info(msg);
  }

  showLoading() {
    this.spinner.show();
  }

  hideLoading() {
    this.spinner.hide();
  }

  defaultDdValue(): { title: string; id: number } {
    return {
      title: '--- Please Select---',
      id: 0,
    };
  }
  setNewDateValue(date) {
    var tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(date - tzoffset)
      .toISOString()
      .slice(0, -1);
    return localISOTime;
  }

  setNewDateObj(date) {
    var tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(date - tzoffset);
  }
}
