import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Env_Config } from 'src/app/models/config';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  envConfig: Env_Config = <Env_Config>{};

  constructor(private http: HttpClient) {}

  loadConfig(): Promise<void> {
    const date = new Date();

    return this.http
      .get<Env_Config>('./assets/config/config.json?t=' + date.getTime())
      .toPromise()
      .then((data) => {
        this.envConfig = data;
      });
  }

  getConfig(): Env_Config {
    return this.envConfig;
  }
}
