export const Constants = {
    VALIDATION_MSG: {
        SIGN_UP: {
            USER_NAME_REQUIRED: 'User name is required',
            PASSWORD_REQUIRED: 'Password is required',
        },
        Company_INFO :{
            Company_Name:'Company name is required'
        } 
    },
    ENGAGE_PORTAL_LOGIN_DATA: 'ENGAGE_PORTAL_LOGIN_DATA',
    ENGAGE_PORTAL_TOKEN: 'ENGAGE_PORTAL_TOKEN',
    NO_INTERNET_CONNECTION_MSG: 'No internet connection!',
    BTN_NEW: 'New',
    BTN_EDIT: 'Edit',
    BTN_SAVE: 'Save',
    BTN_CANCEL: 'Cancel'
}






