import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';
import { UtilityService } from '../shared/service/utility.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  token = "";
  constructor(private alertService: UtilityService, private route: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      this.token = localStorage.getItem("ENGAGE_PORTAL_TOKEN");
      let clonedReq = request.clone({
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.token })
      });
      return next.handle(clonedReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          this.alertService.hideLoading();
          if (error.status === 401 || error.status === 403) {
            this.alertService.showErrorToast('Session expired!');
            this.route.navigate(['/login']);
          }
          return throwError(error);
        }), finalize(() => {

        }));
  }
}


