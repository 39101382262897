import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GridModule } from '@progress/kendo-angular-grid';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { HttpConfigInterceptor } from './core/http-config.interceptor';
import { AppConfigService } from './shared/service/app-config.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

export function initConfig(appConfig: AppConfigService) {
  return async () => await appConfig.loadConfig().then();
}

@NgModule({
  declarations: [AppComponent,],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    GridModule,
    UploadModule,
    HttpClientModule,
    DateInputsModule,
    DropDownsModule,
    IndicatorsModule,
    TooltipModule,
    ButtonsModule,
    EditorModule,
    InputsModule,
    LabelModule,
    ListViewModule,
    CKEditorModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
